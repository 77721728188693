import * as React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import { FiCalendar, FiFacebook, FiLink, FiLinkedin, FiTwitter, FiUser } from "react-icons/fi"
import '../styles/article-post.scss'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const ArticlePage = ({ data }: any) => {  
  const article = data.strapiArticle
  const articleUrl = 'https://deltafuturum.nl/blog/' + article.slug

  return (
    <>
      {/* SEO */}
      <Seo seo={{
        metaTitle: article.title,
        shareImage: article.cover?.localFile.url
      }}/>

      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <section className="bg-brand-blue">
        <div className="container mx-auto py-16 flex flex-col items-center article-shapes">
          <h1 className="text-3xl text-white mb-2">{article.title}</h1>
          <div className="flex gap-6 text-slate-300">
            <div className="flex gap-2 items-center">
              <FiUser/> Jim van IJcken
            </div>
            <div className="flex gap-2 items-center">
              <FiCalendar/> {article.createdAt}
            </div>
          </div>
        </div>
      </section>      

      {/* Blog Content */}
      <section className="max-w-prose mx-6 md:mx-auto mt-16">
        <div className="img-deco-diagonal mb-6">
        <GatsbyImage
          image={getImage(article?.cover?.localFile)}
          alt={article?.cover?.alternativeText}
          className="col-start-3 col-span-4"
        />
        </div>
        <div
          className="block-rich-text"
          dangerouslySetInnerHTML={{
            __html: article.body.data.childMarkdownRemark.html,
          }}
        />
      </section>

      {/* Share */}
      <div className="p-12 mb-16 bg-brand-blue-light mx-auto max-w-prose flex items-center gap-6 text-brand-blue">
        <h3 className="text-2xl font-bold mr-auto w-full sm:w-auto">Dit artikel delen</h3>
        <a href={"https://twitter.com/share?url=" + articleUrl + "&text=" + article.title + "&via=deltafuturum"}>
          <FiTwitter size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
        <a href={"https://www.facebook.com/sharer.php?u=" + articleUrl}>
          <FiFacebook size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
        <a href={"https://www.linkedin.com/shareArticle?url=" + articleUrl + "&title=" + article.title}>
          <FiLinkedin size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
        <a href="javascript:void(0)">
          <FiLink size="24" className="hover:text-brand-green transition-colors cursor-pointer"/>
        </a>
      </div>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      body {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      createdAt(formatString: "DD-MM-YYYY")
    }
  }
`

export default ArticlePage